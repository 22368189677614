.footer {
   padding-top: 2rem;
}

main{
  width: 100%;
  padding: 20px;
}
.sidebar{
  color: rgba(0, 0, 0, 0.55);
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
}
.top_section{
  display: flex;
  align-items:center;
  padding:20px 15px;
}
.logo{
  font-size: 30px;
}
.bars{
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link{
  display: flex;
  color: rgba(0, 0, 0, 0.55);
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover{
  background:lightskyblue;
  color: #000;
  transition: all 0.5s;
}
.active{
    background: lightskyblue;
    color: #000;
}
.icon, .link_text{
  font-size: 20px;
}

@media (max-width: 575.98px) {
  .fontsize{
    font-size: 1,5rem;
  }
  .fontsize2{
    font-size: 1rem;
  }
  .footer{
    padding-top: 0;
  }
  
}
@media (max-width: 432px) {
  .fontsize{
    font-size: 1rem;
  }
  .fontsize2{
    font-size: 0,75rem;
  }
  .footer{
    padding-top: 0;
  }
  
}
@media (min-width: 1400px) {
 
  
  .footer{
    padding-top: 5rem;
  }
  
}


.product {
  display: flex;
}

.product--image {
  order: 1;
}

.product--detail {
  order: 2;
  @media only screen and (min-width: 600px) {
     order: 0;
  }
}

.numbered-pagination {
  .swiper-pagination-bullet {
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    font-size: 0.8rem;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 1rem;  // Increased size
    height: 1rem; // Increased size
    color: #fff;
  font-weight: bolder;  
  
  }

  .swiper-pagination-bullet[data-bullet="T"] {
    &:hover {
      background-color: #007aff !important;
    }
  }
}

.vertical-swiper {
  .swiper-slide {
    text-align: left;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transform: translateX(5px);
      transition: transform 0.3s ease;
    }
  }

  .swiper-pagination {
    display: none !important; // Always hide pagination bullets
  }

  .swiper-pagination-bullet {
    background: #666;
    opacity: 0.5;
    width: 8px;
    height: 8px;
  }

  .swiper-pagination-bullet-active {
    background: #007aff;
    opacity: 1;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #007aff;
    &:after {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 992px) {
    .swiper-pagination {
      display: none; // Hide pagination bullets
    }
  }
}

@media screen and (max-width: 1024px) {
  .swiper-pagination-bullet {
    width: 13px !important;
    height: 13px !important;
    margin: 0 3px !important;
    margin-top: 10px !important; // Added margin-top
  }
  
  .swiper-pagination-bullet-active {
    width: 13px !important;
    height: 13px !important;
    margin-top: 10px !important; // Added margin-top
  }

  .swiper-pagination-bullet[data-bullet="T"] {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  .swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    margin: 0 5px !important;
    font-size: 12px !important;
    line-height: 15px !important;
  }
  
  .swiper-pagination-bullet-active {
    width: 15px !important;
    height: 15px !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 60px;
    height: 60px;
    
    &::after {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    margin: 0 3px !important;
  }
  
  .swiper-pagination-bullet-active {
    width: 10px !important;
    height: 10px !important;
  }
}

@media screen and (max-width: 480px) {
  .swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important;
    margin: 0 2px !important;
  }
  
  .swiper-pagination-bullet-active {
    width: 8px !important;
    height: 8px !important;
  }
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.latest-questions {
  .card {
    transition: transform 0.2s ease;
    &:hover {
      transform: translateY(-2px);
    }
  }
}
